@charset 'UTF-8';

.c-img {
  display: block;
  position: relative;
  img {
    display: block;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &--contain {
    img {
      object-fit: contain;
    }
  }
  &--top {
    img {
      object-position: top;
    }
  }
}

.c-fw {
  width: 100%;
}

[rel~="js-lazy"] {
  opacity: 0;
  &:not(.initial) {
    transition: opacity 0.15s ease-out;
  }
  &.initial,
  &.loaded,
  &.error {
    opacity: 1;
  }
}

.c-lazybg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &--top {
    background-position: center top;
  }
  &--contain {
    background-size: contain;
  }
}
.thumb {
  width: 100%;
  position: relative;
  .img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
// common all page
.btn-read-more {
  max-width: 174px;
  width: 100%;
  @include SP {
    margin: 0 auto;
  }
  a {
    width: 100%;
    display: block;
    position: relative;
    transition: color 0.3s ease-out;
    &::before {
      content: "";
      // width: 0;
      width: 100%;
      height: 1px;
      background-color: #000000;
      position: absolute;
      left: 0;
      bottom: -1px;
      transition: all 0.3s ease-out;
    }
    span {
      display: block;
      font-family: "EB Garamond";
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.15em;
      line-height: 40px;
      text-align: left;
      color: #000;
    }
    i.ico {
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      transform: translateY(-50%);
      transition: all 0.3s ease-out;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%23061826'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      @include SP {
        top: calc(50% - 1px);
      }
    }
  }
  @include HOVER {
    &:hover {
      a {
        opacity: 1;
        span {
          color: $color_light_brown;
        }
        i.ico {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%236c1c25'/%3E%3C/svg%3E%0A");
        }
        &::before {
          animation: widthPercent 1s;
          background-color: $color_light_brown;
        }
        &::after {
          opacity: 0.1;
        }
      }
    }
  }
}

.btn-read-default {
  max-width: 262px;
  height: 60px;
  border: 1px solid #000;
  transition: all 0.3s ease-out;
  width: 100%;
  @include SP {
    margin: 0 auto;
  }
  a {
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    @include font(15, 1, 0.15, 500);
  }
  @include HOVER {
    &:hover {
      height: 60px;
      background-color: #000;
      border-color: transparent;
      span {
        color: #fff;
      }
    }
  }
}
.title-heading {
  &__en {
    color: $color_heading;
    @include eb();
    @include font(45, 48, 0.05, 500);
  }
  &__jp {
    @include font(16, 33, 0.15, 500);
  }
  &--center {
    text-align: center;
  }
}
.breadcrumb {
  @include PC {
    // max-width: 1360px;
    width: 100%;
    margin: 19px auto 9px;
    padding: 0 93px;
  }
  // @include screen(768px, 1360px) {
  //   padding-right: 0;
  // }
  @include SP {
    padding: 0 20px;
    margin: 15px 0 15px auto;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .box-scroll {
    @include PC {
      max-width: 750px;
      margin-left: auto;
      margin-right: 0;
      padding-bottom: 2px;
      .os-scrollbar-horizontal {
        height: 7px;
      }
    }
  }
  &__inner {
    display: block;
    @include PC {
      width: fit-content;
      white-space: nowrap;
      margin-left: auto;
      margin-right: 0;
    }
    @include SP {
      text-align: right;
      width: max-content;
      margin-left: auto;
    }
  }
  li {
    padding-right: 17px;
    position: relative;
    display: inline-flex;
    @include SP {
      padding-right: 10px;
      display: inline;
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    &:after {
      content: ">";
      position: absolute;
      transform: translateY(-50%);
      font-size: 14px;
      line-height: 1;
      border-radius: 50%;
      color: $color_body;
      top: 50%;
      margin-top: -1px;
      right: 9px;
      transform: translateY(-50%);
      @include SP {
        right: 6px;
        font-size: 11px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
      }
    }
    a,
    span {
      @include font(12, "", 0.03em, 500);
      overflow: hidden;
      color: #30343b;
    }
    a {
      position: relative;
      margin-right: 15px;
      text-align: left;
      text-decoration: underline;
      @include PC {
        display: inline-block;
      }
      // @include SP {
      //   text-decoration: underline;
      // }
    }
    span {
      letter-spacing: 0.5px;
    }
  }
}

.visual {
  height: 439px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  @include SP {
    height: 295px;
  }
  &__box {
    display: flex;
    align-items: flex-end;
    color: #fff;
    @include SP {
      padding-top: 40px;
    }
  }
  &__jp {
    font-size: 55px;
    line-height: 62px;
    font-family: $ship;
    display: flex;
    @include SP {
      font-size: 35px;
      line-height: 40px;
    }
    span {
      display: block;
      &:nth-child(2) {
        margin: 0 9px 0 12px;
        @include SP {
          margin: 0 20px 0px 9px;
        }
      }
    }
  }
  &__en {
    font-size: 28px;
    letter-spacing: 0.15em;
    writing-mode: tb-rl;
    font-family: $eb;
    padding-left: 15px;
    @include SP {
      font-size: 18px;
      line-height: 15px;
    }
  }
  &--single {
    height: auto;
    justify-content: space-between;
    align-items: flex-end;
    background-image: none !important;
    padding-left: calc((100% - 1076px) / 2);
    padding-right: 94px;
    margin-top: 133px;
    @media (max-width: 1122px) {
      padding-left: 23px;
    }
    @include SP {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
      margin-top: 100px;
    }
    .visual__box {
      color: #000;
      @include SP {
        width: 100%;
        justify-content: center;
        padding-top: 0;
        margin-bottom: 30px;
      }
    }
    .visual__jp {
      font-size: 34px;
      line-height: 42px;
    }
    .visual__en {
      font-size: 17px;
      line-height: 12px;
      padding-bottom: 10px;
    }
    .breadcrumb {
      padding: 0 0 0 10px;
      position: relative;
      top: -70px;
      @include SP {
        padding: 0 23px;
        width: 100%;
        top: auto;
      }
      &__inner {
        padding-right: 0;
      }
    }
  }
}

.visual-v3 {
  height: 439px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
  .hd-ttl {
    @include ship();
    @include font(38, 50, 320, 400);
    writing-mode: vertical-lr;
    white-space: nowrap;
    flex-grow: 1;
    display: flex;
    span {
      margin: 11px auto 0;
      display: block;
      @include SP {
        margin-top: 7px;
      }
    }
    @include SP {
      @include font(24, 30, 250, 400);
    }
  }
  .img {
    width: 86.1vw;
    max-height: 439px;
    height: 100%;
    overflow: hidden;
    transform: translateX(1px);
    picture {
      display: block;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    @include SP {
      width: 77.5vw;
    }
    @include screen(768px, 1359px) {
      height: 362px;
    }
  }
  @include SP {
    height: 193px;
    margin-bottom: -4px;
  }
  @include screen(400px, 767px) {
    margin-bottom: 0;
  }
  @include screen(768px, 1359px) {
    height: 362px;
    align-items: stretch;
  }
}

.paging {
  padding: 48px 0 0;
  margin-top: 40px;
  border-top: 1px dashed #767676;
  @include SP {
    padding: 48px 0 0;
    margin-top: 20px;
  }
  ul {
    display: flex;
    li {
      width: calc(50% - 19px);
      margin-bottom: auto;
      a {
        font-size: 18px;
        font-family: $eb;
        font-weight: 500;
        letter-spacing: 0.1em;
        .txt {
          position: relative;
          width: max-content;
          &:after {
            display: block;
            content: "";
            width: 0;
            height: 1px;
            background: #1b1c1f;
            transition: 0.3s all ease;
            position: absolute;
            left: 0;
            bottom: -5px;
          }
          @include PC {
            &.prev {
              margin-left: 2px;
            }
            &.next {
              margin-right: -2px;
            }
          }
        }
        .post__thumb {
          max-width: 159px;
          position: relative;
          @include aspect-ratio(159, 90);
          overflow: hidden;
          margin: 16px 0 23px;
          img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
          }
          &.next {
            margin-left: auto;
          }
          @include SP {
            max-width: 128px;
            @include aspect-ratio(128, 72);
            margin: 18px 0 11px;
          }
        }
        .post__info {
          @include font(13, 26, 50, 500);
          font-family: $yugothic;
          max-width: 257px;
          .ttl {
            @include multiLineEllipsis(2);
            @include SP {
              @include multiLineEllipsis(4);
            }
          }
          &.next {
            margin-left: auto;
          }
          @include SP {
            @include font(13, 24, 50, 500);
            max-width: 126px;
          }
        }
        @include HOVER {
          opacity: 1;
          .txt {
            &:after {
              width: 100%;
            }
          }
          .post__thumb {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      &:nth-child(2) {
        width: 38px;
        padding-top: 65px;
        margin-bottom: unset;
        @include SP {
          padding-top: 0;
        }
        .icon02 {
          display: none;
        }
        a {
          @include HOVER {
            .icon01 {
              display: none;
            }
            .icon02 {
              display: inline-block;
            }
          }
          &:after {
            display: none;
          }
        }
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.block-event {
  .top {
    position: relative;
    .thumb {
    }
    .tag-info {
      .lst-cat {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        line-height: 1;
        span {
          display: inline-flex;
          @include font(12, 1.4, 0.05, 500);
          color: #fff;
          align-items: center;
          min-height: 24px;
          padding: 3px 10px;
          min-width: 100px;
          margin-right: 10px;
          margin-bottom: 5px;
          background-color: #1b1c1f;
          justify-content: center;
        }
      }
      .news {
        width: 62px;
        height: 40px;
        background: url(../img/common/icon/ico-new.svg) no-repeat center bottom;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -34px;
        right: 0;
        z-index: 9;
        span {
          @include eb();
          @include font(16, 40, 0.02, 400);
          display: inline-block;
          transform: translateY(-9px);
        }
      }
      .discount {
        color: #837556;
        width: 62px;
        height: 62px;
        background: rgba($color: #fff, $alpha: 0.8);
        border: 1px solid #a2977f;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(13, 25, 0.1, bold);
        position: absolute;
        bottom: -20px;
        right: 11px;
        z-index: 9;
      }
    }
  }
}

.slider__pagination {
  position: relative;
  text-align: center;
  display: block;
  right: 5.5%;
  left: auto;
  .swiper-pagination-bullet {
    width: 37px;
    height: 1px;
    background: #000;
    border-radius: 0;
    position: relative;
    @include SP {
      width: 30px;
    }
    &.swiper-pagination-bullet-active {
      height: 3px;
      background: #1b1c1f;
      top: 1px;
    }
  }
}
.block-works {
  position: relative;
  @include PC {
    padding: 108px 20px 87px;
  }
  @include SP {
    padding: 62px 0 95px;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 100%;
      background: #fff;
      z-index: 9;
    }
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    left: auto;
    right: auto;
    bottom: 84px;
    @include SP {
      bottom: 59px;
    }
  }
  .btn-read-default {
    margin: 0 auto;
    @include SP {
      margin-top: -16px;
    }
  }

  .tag-info {
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: -45px;
    @include SP {
      top: -42px;
      right: 0px;
    }
    .news {
      width: 62px;
      height: 40px;
      background: url(../img/common/icon/ico-new.svg) no-repeat center bottom;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        @include eb();
        @include font(16, 40, 0.02, 400);
        display: inline-block;
        transform: translateY(-9px);
        @include SP {
          font-size: 14px;
        }
      }
      @include SP {
        width: 56px;
        height: 37px;
      }
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
  }
  &__list {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin: 87px auto 0;
    padding: 0 0 13px;
    &.oneRow {
      justify-content: center;
    }
    @include PC {
      &.inner {
        flex-wrap: wrap;
        align-items: baseline;
        transform: none !important;
        .swiper-slide-duplicate {
          display: none;
        }
      }
    }
    @include SP {
      margin: 28px auto 66px;
      padding: 0 0 37px;
      align-items: self-end;
    }
  }
  &__author {
    color: #767676;
    margin-top: 12px;
    @include font(13, 28, 0.1, 500);
    @include SP {
      margin-top: 6px;
      @include font(12);
    }
  }
  &__video {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    @include eb();
    @include font(15, "", 0.05, 400);
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 9px;
    @include PC {
      width: 142px;
      height: 46px;
    }
    @include SP {
      font-size: 12px;
      line-height: 16px;
      width: 116px;
      height: 38px;
    }
    span {
      padding-left: 30px;
      background-image: url("data:image/svg+xml,%3Csvg id='Group_80272' data-name='Group 80272' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22.948' height='16.063' viewBox='0 0 22.948 16.063'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_15573' data-name='Rectangle 15573' width='22.948' height='16.063' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_79986' data-name='Group 79986' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_364517' data-name='Path 364517' d='M22.468,2.508A2.874,2.874,0,0,0,20.44.479C18.65,0,11.474,0,11.474,0S4.3,0,2.508.479A2.874,2.874,0,0,0,.48,2.508,29.857,29.857,0,0,0,0,8.032a29.859,29.859,0,0,0,.48,5.523,2.874,2.874,0,0,0,2.029,2.029c1.79.48,8.966.48,8.966.48s7.176,0,8.966-.48a2.874,2.874,0,0,0,2.029-2.029,29.859,29.859,0,0,0,.48-5.523,29.857,29.857,0,0,0-.48-5.523M9.179,11.474V4.59l5.962,3.442Z' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: 22px;
      background-position: center left;
      background-repeat: no-repeat;

      @include SP {
        background-size: 18px;
        padding-left: 26px;
      }
    }
  }
  &__ttl {
    @include font(17, 30, 0.1, 500);
    margin: 17px 0 0;
    @include screen(768px, 1200px) {
      @include font(14, "", 0.05);
    }
    @include SP {
      font-size: 14px;
      line-height: 22px;
      margin-top: 14px;
      @include multiLineEllipsis(2);
    }
  }
  &__cont {
    @include SP {
      padding: 0 8px;
    }
  }
  &__hd {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // max-width: calc(100% - 69px);
    max-width: max-content;
    min-height: 48.6px;
    &.old {
      max-width: 100%;
    }
  }
  &__chap {
    margin-bottom: 9px;
    display: flex;
    align-items: flex-end;
    span {
      @include font(11, 22, 0.03, 500);
      em {
        font-style: normal;
        position: relative;
        padding-right: 4px;
        display: inline;
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   right: 0;
        //   width: 1px;
        //   height: 13px;
        //   background: #fff;
        // }
        &.second-row {
          &:first-of-type {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
      &.cate {
        background-color: #74716b;
        color: #fff;
        padding: 2px 11px 3px;
        line-height: 1.5em;
        @include SP {
          display: block;
          width: fit-content;
          margin-top: 3px;
          padding: 2px 9px 3px;
        }
        .cate__item{
          i{
            font-style: normal;
          }
          &:last-child{
            i{
              display: none;
            }
          }
        }
      }
      &.case {
        margin-right: 15px;
        white-space: nowrap;
      }
    }
    .txt1 {
      display: inline-block;
      @include font(11, 22, 0.03, 500);
      &.case {
        white-space: nowrap;
      }
      @include SP {
        line-height: 16px;
      }
    }
    @include SP {
      margin-bottom: 14px;
      // flex-direction: column;
      // align-items: start;
      flex-wrap: wrap;
    }
  }
  &__status {
    @include eb();
    @include font(16, 10, 20, 400);
    position: relative;
    width: 62px;
    top: -7px;
    left: 4px;
    text-align: center;
    &::after {
      content: "";
      width: 62px;
      height: 14px;
      background: url(../img/top/ico-new.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      z-index: 1;
    }
    @include SP {
      margin-right: 5px;
      transform: translateY(5px);
      font-size: 14px;
    }
  }
  &__img {
    position: relative;
  }
  &__item {
    max-width: 330px;
    width: 100%;
    position: relative;
    @include screen(768px, 1200px) {
      // padding-left: 0;
      // max-width: calc(100% / 3 - 24px);
      max-width: 33.33%;
      margin: 0 !important;
      padding: 0 23px 0;
      margin-bottom: 56px !important;
    }
    a {
      opacity: 1;
      position: relative;
      display: block;
    }
    @include PC {
      margin: 0 22px 38px;
      // &:nth-child(3n +3 ) {
      //   margin: 0;
      // }
    }
    @include HOVER {
      &:hover {
        .img {
          transform: scale(1.1);
        }
        .block-works__ttl {
          color: $color_light_brown;
        }
      }
    }
    @include SP {
      max-width: calc((100vw - 25px) / 2);
      margin: 0 6px 0 7px;
      margin-top: auto;
    }
    .thumb {
      position: relative;
      overflow: hidden;
    }
    .img {
      transition: all 0.3s ease-out;
      @include PC {
        @include aspect-ratio(330, 412);
      }
      @include SP {
        @include aspect-ratio(175, 218);
      }
    }
  }

  //mdf design
  &__item {
    &.half {
      .tag-info {
        top: -78px;
      }
      @include SP {
        margin-top: 30px !important;
      }
    }
    &.twoLines {
      .tag-info {
        top: -93px;
      }
      @include PC {
        &:not(:nth-child(-n + 3)) {
          margin-top: 40px !important;
        }
      }
      @include SP {
        margin-top: 30px !important;
      }
    }
    &.threeLines {
      .tag-info {
        top: -108px;
      }
      @include PC {
        &:not(:nth-child(-n + 3)) {
          margin-top: 30px !important;
        }
      }
      @include SP {
        margin-top: 30px !important;
      }
    }
  }
}

.list-cat {
  &__ttl {
    @include eb();
    @include font(13, 1, 0.1, 500);
    position: relative;
    text-align: center;
    padding-bottom: 17px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 48px;
      height: 1px;
      background: #1b1c1f;
      margin: 0 auto;
    }
  }
  &__list {
    @include PC {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      margin: 37px auto 0;
    }
    @include SP {
      z-index: 10;
      width: 100%;
      box-sizing: border-box;
      border-top: none;
      height: 0;
      max-height: 0;
      transition: height 0.3s;
      visibility: hidden;
      opacity: 0;
    }
  }
  &__item {
    @include PC {
      margin: 0 21px;
    }
    @include SP {
      margin: 0 18px;
    }
    a {
      text-align: center;
      position: relative;
      padding-bottom: 0;
      opacity: 1;
      display: block;
      background: transparent;
      @include PC {
        height: 32px;
        padding: 0 10px;
      }
      @include SP {
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #1b1c1f;
        border-top: none;
        background: #fff;
      }
      span {
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 0.03em;
        line-height: 32px;
        color: #000;
        position: relative;
        z-index: 2;
        display: block;
        text-align: center;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          margin: 0 auto;
          background: #1b1c1f;
          opacity: 0;
        }

        @include SP {
          font-weight: 500;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0px 6px 0px 0px;
        background: #1b1c1f;
        width: 100%;
        height: 100%;
        opacity: 0;
        @include SP {
          display: none;
        }
      }
    }
    &:first-child {
      @include PC {
        margin: 0 31px 0 29px;
      }
      a {
        padding: 0;
        span {
          min-width: 43px;
          font-weight: 500;
        }
      }
    }
    &.is-active {
      @include PC {
        a {
          color: #fff;
          &::before {
            opacity: 1;
          }
          span {
            color: #fff;
          }
        }
      }
    }
    @include HOVER {
      &:hover {
        a {
          span {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .cat-choose {
    width: 339px;
    height: 78px;
    margin: 23px auto 0;
    background-color: #fff;
    border: 1px solid #1b1c1f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0.15em;
    line-height: 35px;
    padding-top: 4px;
    position: relative;
    @include SP {
      max-width: calc(100% - 36px);
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      top: 28px;
      right: 18px;
      width: 22px;
      height: 22px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath id='Path_364806' data-name='Path 364806' d='M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z' fill='%23e0e0e0'/%3E%3Cg id='Group_80236' data-name='Group 80236' transform='translate(-633.999 -317.492)'%3E%3Cpath id='Path_364796' data-name='Path 364796' d='M-9143-19918.072v9.2' transform='translate(9788 20241.965)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3Cpath id='Path_364797' data-name='Path 364797' d='M-9143-19918.072v9.2' transform='translate(20558.471 -8814.508) rotate(-90)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      animation: spin 0.3s linear;
    }
  }
  &.is-click-cat {
    @include SP {
      .cat-choose {
        &::after {
          transform: rotate(180deg);
          animation: rotate 0.3s linear;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g id="Group_82823" data-name="Group 82823" transform="translate(-311 -4485)"><path id="Path_364806" data-name="Path 364806" d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z" transform="translate(311 4485)" fill="%23e0e0e0"/><path id="Path_364797" data-name="Path 364797" d="M0,0V9.2" transform="translate(317.399 4496) rotate(-90)" fill="none" stroke="%231b1c1f" stroke-width="1"/></g></svg>');
        }
      }
      .list-cat__list {
        height: 100%;
        max-height: 100%;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.is-safari {
  @include screen(370px, 380px) {
    .block-works {
      &__list {
        align-items: flex-end;
      }
    }
  }
}
body {
  @include SP {
    .header {
      height: 70px;
    }

    &.layerOn {
      .header {
        height: unset;
        .layerMenu {
          display: none;
        }
        .inHeader {
          background-color: #fff;
        }
      }
    }
  }
}
