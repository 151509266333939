@charset 'UTF-8';
@keyframes widthPercent {
  0% {
    background-color: $color_light_brown;
    width: 0%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    background-color: $color_light_brown;
    width: 0%;
    opacity: 1;
  }
  100% {
    width: 100%;
    background-color: $color_light_brown;
    opacity: 1;
  }
}
@keyframes imgoverlay {
  0% {
    right: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }

  50% {
    right: 0%;
    left: 0%;
    bottom: 0;
    top: 0;
  }

  100% {
    right: 0;
    left: 100%;
    top: 0;
    bottom: 0;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(-180deg);
  }
}
.js-fadeup {
  opacity: 0;
  transform: translateY(50%);
  transition: all 1s;
  &.is-inview-fadeup {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-fadein {
  opacity: 0;
  transform-origin: center;
  transform: scale(1.05);
  transition: all 2s 0.2s ease;
  &.is-inview-fadein {
    opacity: 1;
    transform: scale(1);
  }
}
.inview-img {
  position: relative;
  width: 100%;
  overflow: hidden;
  &::after {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    background: #fff;
    right: 0;
    left: 100%;
  }
  &::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    background: #fff;
    right: 100%;
    left: 0;
    width: 100%;
  }
  // .img {
  //   &::after {
  //     transition: 0.5s ease-in;
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //     background: #fff;
  //     right: 0;
  //     z-index: 2;
  //   }
  // }
  &.is-inview-img {
    &::after {
      animation: imgoverlay 1.6s ease 0s 1 normal forwards;
    }
    &::before {
      animation: imgoverlay 1.6s ease 0s 1 normal forwards;
    }
    // .img {
    //   transition-delay: 1s;
    //   &::after {
    //     background: transparent;
    //   }
    // }
  }
}
.inview-text {
  opacity: 0;
  transition: all 0.3s ease-out;
  transition-delay: 1s;
  transform: translateY(16px);
  transition-property: opacity, transform;
  &.is-inview-text {
    opacity: 1;
    transform: translateY(0);
  }
}
