.cmn_pagenavi {
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 0;
    position: relative;
    @include SP {
      padding-top: 70px;
    }
    a.page,
    span {
      display: inline-flex;
      width: 40px;
      height: 40px;
      font-size: 16px;
      font-family: $eb;
      letter-spacing: 0.025em;
      font-weight: 500;
      text-align: center;
      align-items: center;
      justify-content: center;
      line-height: 36px;
      margin: 0 5px;
      position: relative;
      @include SP {
        margin: 0;
      }
    }
    a.page {
      @include HOVER {
        opacity: 1;
        &:after {
          display: block;
          content: "";
          width: 32px;
          height: 32px;
          background: #e0e0e0;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }
    .pages {
      display: none;
    }
    a.last {}
    span.current {
      border-radius: 50%;
      border: 1px solid #1b1c1f;
    }
    span.extend {
      margin-right: 20px;
      @include SP {
        margin-right: 10px;
      }
    }
    a.first, a.last {
      display: none;
    }
    a.previouspostslink,
    a.nextpostslink {
      font-size: 18px;
      letter-spacing: 0.1em;
      font-family: $eb;
      font-weight: 500;
      position: relative;
      @include SP {
        //position: absolute;
        min-width: 43px;
      }
      &:after {
        display: block;
        content: "";
        width: 0;
        height: 1px;
        background: #1b1c1f;
        transition: 0.3s all ease;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      @include HOVER {
        opacity: 1;
        &:after {
          width: 100%;
        }
      }
    }
    a.previouspostslink {
      margin-right: 70px;
      @include SP {
        left: 0;
        margin-right: 0;
      }
    }
    a.nextpostslink {
      margin-left: 70px;
      @include SP {
        right: 0;
        margin-left: 0;
      }
    }
  }
}