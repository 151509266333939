@charset 'UTF-8';
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  transition: all 0.3s ease-out;
  background-color: none;
  .logo {
    position: relative;
    z-index: 99999;
    @include SP {
      img {
        width: 135px;
        @include max-screen(374px) {
          width: 120px;
        }
      }
    }
    a {
      transition: all 0.3s ease-out;
    }
    img {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}
.hamberger {
  position: fixed;
  right: 23px;
  top: 23px;
  width: 40px;
  height: 40px;
  z-index: 999999;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  padding: 15px 0;
  transition: all 0.3s ease-out;
  @include SP {
    right: 15px;
    top: 17px;
  }
  @include HOVER {
    &:hover {
      opacity: 0.6;
    }
  }
  span {
    display: block;
    width: 40px;
    height: 1px;
    margin-bottom: 5px;
    background: #fff;
    @include add_prefix(transition, 0.3s ease all);
  }
  &.active {}
  @include max-screen(767px) {
    display: block;
  }
}
.inHeader {
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99999;

  @include PC {
    padding: 11px 102px 12px 34px;
  }
  @include SP {
    padding: 22px 13px;
  }
  @include max-screen(374px) {
    align-items: center;
  }
}
.layerMenu {
  .gNavi {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    transition: all 0.3s ease-out;
    @include PC {
      margin: 12px 0 12px 0;
    }
    @include SP {
      width: 100%;
      height: 53px;
      justify-content: center;
      align-items: center;
    }
    @include max-screen(374px) {
      margin-right: 50px;
    }
    &-item {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      @include SP {
        margin-right: 0;
        width: 33.33%;
        &:not(:last-child) {
          border-right: 1px solid #e0e0e0;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      opacity: 1;
      position: relative;
      transition: all 0.3s ease-in-out;
      &::after {
        content: "";
        width: 0;
        height: 1px;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
      }
      i {
        display: inline-block;
        margin-right: 5px;
        transform: translateY(-1px);
        transition: all 0s ease-in-out;
        &.modelhouse {
          width: 20px;
          height: 15px;
          background: url(../img/common/icon/ico-house.svg) no-repeat;
          background-size: contain;
          @include SP {
            background: url(../img/common/icon/ico-house-black.svg) no-repeat;
            background-size: contain;
          }
        }
        &.event {
          width: 13px;
          height: 18px;
          background: url(../img/common/icon/ico-flag.svg) no-repeat;
          background-size: contain;
          margin-right: 6px;
          @include SP {
            background: url(../img/common/icon/ico-flag-black.svg) no-repeat;
            background-size: contain;
          }
        }
        &.consultation {
          width: 19px;
          height: 14px;
          background: url(../img/common/icon/ico-book.svg) no-repeat;
          background-size: contain;
          @include SP {
            background: url(../img/common/icon/ico-book-black.svg) no-repeat;
            background-size: contain;
          }
        }
      }
      @include HOVER {
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
      @include SP {
        padding: 0;
        justify-content: center;
      }
    }
    span {
      color: #fff;
      @include font(14, 38, 40, 500);
      @include screen(768px, 1200px) {
        @include font(12, "", 0);
      }
      @include SP {
        @include font(12, 38, 20, 500);
        color: #000;
      }
    }
  }
  @include SP {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
}
.gSocial {
  position: fixed;
  top: 105px;
  right: 28px;
  width: 30px;
  height: 175px;
  text-align: center;
  &-item {
    margin-bottom: 19px;
    &:last-child {
      margin: 0;
    }
    a {
      display: block;
      position: relative;
      text-align: center;
      margin: 0 auto;
      opacity: 1;
      cursor: pointer;
      svg {
        width: 0;
        height: 0;
        position: absolute;
        display: block;
      }
      @include PC {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          z-index: 9;
          opacity: 0;
          transition: all 0.3s ease-out;
        }
      }
      .icon {
        -webkit-backdrop-filter: invert(1);
        backdrop-filter: invert(1);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.youtube {
        width: 23px;
        height: 16px;
        .icon {
          -webkit-clip-path: url(#icon-youtube);
          clip-path: url(#icon-youtube);
        }
      }
      &.instagram {
        width: 18px;
        height: 19px;
        .icon {
          -webkit-clip-path: url(#icon-instagram);
          clip-path: url(#icon-instagram);
        }
      }
      &.facebook {
        width: 20px;
        height: 20px;
        .icon {
          -webkit-clip-path: url(#icon-facebook);
          clip-path: url(#icon-facebook);
        }
      }
      &.tiktok {
        width: 18px;
        height: 20px;
        .icon {
          -webkit-clip-path: url(#icon-tiktok);
          clip-path: url(#icon-tiktok);
        }
      }
      &.email {
        width: 30px;
        height: 23px;
        .icon {
          -webkit-clip-path: url(#icon-email);
          clip-path: url(#icon-email);
        }
      }
    }
  }
}
body.fixHeader,
body.page-detail,
body.hd-v3 {
  .header {
    background: #fff;
    .logo {
      @include screen(768px, 1200px) {
        top: 0;
      }
      img {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
    .inHeader {
      @include SP {
        padding: 23px 13px;
      }
    }
    .hamberger {
      @include SP {
        top: 17px;
      }
    }
    .gNavi-item {
      a {
        span {
          transition: all 0.3s ease-out;
          color: #000;
        }
        i {
          &.modelhouse {
            background: url(../img/common/icon/ico-house-black.svg) no-repeat;
            background-size: contain;
          }
          &.event {
            background: url(../img/common/icon/ico-flag-black.svg) no-repeat;
            background-size: contain;
            margin-right: 6px;
          }
          &.consultation {
            background: url(../img/common/icon/ico-book-black.svg) no-repeat;
            background-size: contain;
          }
        }
        @include HOVER {
          &:hover {
            opacity: 1;
            &::after {
              background-color: #000;
            }
          }
        }
      }
    }
    .inHeader {
      @include PC {
        align-items: center;
      }
    }
    .hamberger {
      span {
        background: #000;
      }
    }
  }
}
body.page-form {
  .layerMenu {
    @include SP {
      display: none;
    }
  }
}
body.fixHeader {
  .inHeader {
    @include add_prefix(box-shadow, 0px 0px 5px 1px rgba(0, 0, 0, 0.1));
  }
}
.menuNavi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 30px;
  text-align: left;
  color: #000;
  background: #fff;
  overflow: scroll;
  transform: translateX(10000px);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  &__inner {
    position: relative;
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    padding: 0 82px 150px;
    @include screen(768px, 1200px) {
      padding: 0 20px 150px;
    }
    @include SP {
      padding: 0 18px 120px;
      //height: 100vh;
      // overflow-y: scroll;
      // &::-webkit-scrollbar {
      //   width: 4px;
      // }

      // /* Track */
      // &::-webkit-scrollbar-track {
      //   background: transparent;
      // }

      // /* Handle */
      // &::-webkit-scrollbar-thumb {
      //   background: rgba($color: #000000, $alpha: .4);
      // }

      // /* Handle on hover */
      // ::-webkit-scrollbar-thumb:hover {
      //   background: #555;
      // }
    }
  }
  .menu-social {
    position: relative;
    text-align: center;
    @include flexbox;
    margin-top: 60px;
    a {
      display: block;
      margin-right: 20px;
      &:last-child {
        margin: 0;
        position: relative;
        top: 3px;
      }
    }
  }
  .menu-inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1076px;
    margin: 64px auto 0;
    @include max-screen(1000px) {
      justify-content: space-between;
    }
    @include SP {
      flex-wrap: wrap;
      margin: 0 auto 0;
      padding-bottom: 60px;
    }
  }
  .menu-wrap {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 230px);
    @include screen(768px, 1200px) {
      width: calc(100% - 360px);
    }
    @include SP {
      display: block;
      width: 100%;
      order: 2;
      margin: 50px auto 0;
      padding: 0 5px;
    }
    &__item {
      @include PC {
        &:first-child {
          margin-right: 112px;
          @include max-screen(1000px) {
            margin-right: 10px;
          }
          >li {
            margin-bottom: 32px;
            &.hasSub {
              margin-bottom: 24px;
            }
          }
        }
        &:nth-child(2) {
          padding-right: 8px;
          @include max-screen(1000px) {
            padding-right: 0;
          }
          >li {
            margin-bottom: 36px;
          }
        }
      }
      @include SP {
        &:first-child {
          margin-bottom: 23px;
        }
        &:nth-child(2) {
          padding-bottom: 0;
          @include flexbox;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 45.5%;
            &:first-child {
              ul {
                li {
                  &:first-child {
                    border: none;
                    padding-bottom: 0;
                  }
                }
              }
            }
            &:last-child {
              a {
                span {
                  // min-height: 58px;
                  @include flexbox;
                  justify-content: flex-end;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
      li {
        @include SP {
          background-size: 8px;
          background-repeat: no-repeat;
          width: 100%;
          margin-bottom: 24px;
          &:first-child {
            width: 45.5%;
          }
          &.plus {
            position: relative;
            background: none;
            &::before {
              content: "";
              position: absolute;
              top: -4px;
              right: 8px;
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_87151' data-name='Group 87151' transform='translate(-320 -10879)'%3E%3Cg id='Ellipse_451' data-name='Ellipse 451' transform='translate(320 10879)' fill='none' stroke='%231b1c1f' stroke-width='1'%3E%3Ccircle cx='12' cy='12' r='12' stroke='none'/%3E%3Ccircle cx='12' cy='12' r='11.5' fill='none'/%3E%3C/g%3E%3Cg id='Group_80222' data-name='Group 80222' transform='translate(1.062 1.063)'%3E%3Cpath id='Path_364784' data-name='Path 364784' d='M-6486-9348.749v8.433' transform='translate(6816.938 20235)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3Cpath id='Path_364785' data-name='Path 364785' d='M0,0V8.432' transform='translate(326.722 10890.468) rotate(-90)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
              background-size: 24px;
              z-index: 999;
            }
            &::after {
              content: "";
              width: 50%;
              height: 36px;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 10;
            }
            a {
              padding-right: 40%;
              span {
                &::after {
                  width: calc(100vw - 46px);
                }
              }
            }
            ul {
              display: none;
              li {
                width: 100%;
              }
            }
            &.active {
              border-bottom: 1px solid #adadad;
              &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_82803' data-name='Group 82803' transform='translate(-320 -10879)'%3E%3Cg id='Ellipse_451' data-name='Ellipse 451' transform='translate(320 10879)' fill='none' stroke='%231b1c1f' stroke-width='1'%3E%3Ccircle cx='12' cy='12' r='12' stroke='none'/%3E%3Ccircle cx='12' cy='12' r='11.5' fill='none'/%3E%3C/g%3E%3Cg id='Group_80222' data-name='Group 80222' transform='translate(1.062 1.063)'%3E%3Cpath id='Path_364785' data-name='Path 364785' d='M0,0V8.432' transform='translate(326.722 10890.468) rotate(-90)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
              }
              li {
                a {
                  pointer-events: visible;
                }
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: block;
          width: 100%;
          span {
            display: block;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1;
            text-align: left;
            color: #000;
            position: relative;
            padding: 0 0 13px 10px;
            width: 100%;
            @include screen(768px, 1200px) {
              font-size: 15px;
              letter-spacing: 0;
            }
            @include SP {
              padding-left: 0;
              padding-bottom: 14px;
              @include font(15, 22/15, 100, 400);
            }
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              width: 18px;
              height: 1px;
              background: #a2977f;
              @include SP {
                left: 0;
                width: 100%;
                background: #adadad;
              }
            }
          }
        }
        >ul {
          width: 100%;
          @include PC {
            margin: 28px 0 20px 28px;
          }
          @include SP {
            padding: 24px 0 20px 26px;
          }
          li {
            margin-bottom: 4px;
            @include SP {
              background: none;
              margin-bottom: 18px;
            }
            a {
              span {
                font-size: 14px;
                letter-spacing: 0.1em;
                @include SP {
                  padding-left: 12px;
                  padding-bottom: 0;
                }
                &::before {
                  content: "";
                  background: $color_light_brown;
                  position: absolute;
                  top: 5px;
                  left: -2px;
                  width: 2px;
                  height: 2px;
                  border-radius: 50%;
                  @include screen(768px, 1200px) {
                    top: 5px;
                  }
                  @include SP {
                    display: block;
                    left: 0;
                    top: 10px;
                  }
                }
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .menu-link {
    position: relative;
    padding-top: 68px;
    @include SP {
      padding-top: 0;
      width: calc();
    }
    @include SP {
      order: 1;
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% + 36px);
        margin-left: -18px;
        padding: 50px 15px;
        min-height: 274px;
        background: url(../img/common/bg-navi_sp.jpg) center/cover no-repeat;
      }
    }
    .contact-box {
      margin-top: 36px;
    }
    &__ttl {
      @include font(15, 1.5, 100, 400);
      font-family: $eb;
      text-align: center;
      margin-bottom: 4px;
    }
    &__mail {
      border: 1px solid #1b1c1f;
      @include font(20, 1.5, 100, 400);
      font-family: $eb;
      text-align: center;
      padding: 12px 10px;
      max-width: 313px;
      margin: 0 auto 18px;
      background-color: #fff;
      display: block;
    }
    &__phone {
      max-width: 313px;
      height: auto;
      border: 1px solid #1b1c1f;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      letter-spacing: 0.15em;
      background-color: #fff;
      position: relative;
      margin: 0 auto;
      line-height: 1.5;
      padding: 4px 0;
      &::after {
        content: "";
        width: 21px;
        height: 21px;
        display: block;
        background: url(../img/common/icon/ico-phone.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%);
      }
      span {
        font-family: $eb;
        font-weight: 400;
        font-size: 22px;
        letter-spacing: 0.1em;
        text-align: center;
        color: #1b1c1f;
        display: block;
        margin: 0 auto;
        padding-left: 30px;
        position: relative;
        right: 14px;
        line-height: 1.5;
        margin-bottom: -2px;
      }
    }
    ul {
      li {
        span {
          color: #000;
          text-align: left;
          display: block;
          width: 100%;
        }
      }
    }
    .en {
      font-family: $eb;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.05em;
      line-height: 20px;
      margin-bottom: 7px;
      @include SP {
        font-size: 13px;
        padding-left: 0;
      }
    }
    .jp {
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 0.15em;
      line-height: 20px;
      @include SP {
        font-size: 15px;
        margin-top: 5px;
      }
    }
    .modelhouse {}
    .event {}
    .consultation {
      @include SP {
        a {
          p {
            span.en {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .request {
      @include SP {
        a {
          padding-bottom: 6px;
          p {
            span {
              //margin: 0;
            }
          }
        }
      }
    }
    li {
      position: relative;
      @include SP {
        width: 49.4%;
        //height: 90px;
        background-size: 20px;
        background-position: center right 8px;
        margin-bottom: 0;
        background-color: transparent;
        padding: 5px 15px 15px;
        @include flexbox;
        justify-content: flex-end;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        background-repeat: no-repeat;
        background-size: contain;
        @include SP {
          top: calc(50% - 12px);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-bottom: 1px solid #707070;
        margin-bottom: 32px;
        padding-bottom: 14px;
        @include screen(768px, 1200px) {
          width: 260px;
        }
        @include SP {
          padding: 0 0 13px 0;
          width: 100%;
          margin-bottom: 0;
          //align-items: self-end;
        }
      }
    }
  }
  .menu-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 25px;
    max-width: 798px;
    @include SP {
      order: 3;
      display: block;
    }
    &:last-child {
      margin: 0;
    }
    .modelhouse {
      font-family: $eb;
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.05em;
      line-height: 45px;
      text-align: left;
      color: #1b1c1f;
    }
    .txt {
      font-family: $eb;
      font-weight: 500;
      font-size: 21px;
      letter-spacing: 0.1em;
      line-height: 1;
      text-align: left;
      color: #000;
      display: table;
      border-bottom: 1px solid #000;
      margin-bottom: 14px;
      padding: 0 20px 0 0;
    }
    .inner {
      padding: 0;
    }
    @include SP {
      flex-wrap: wrap;
      margin-bottom: 0;
      &.fukuoka {
        margin-bottom: 55px;
      }
    }
    &__block {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto 25px;
      @include SP {
        width: 100%;
      }
    }
    &__modelhouse {
      font-family: $eb;
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.05em;
      line-height: 26px;
      text-align: left;
      color: #1b1c1f;
      width: 100%;
      margin: 0 0 18px;
      @include SP {
        padding-bottom: 10px;
        margin-bottom: 8px;
      }
    }
    &__img {
      position: relative;
      .add-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9999999;
        text-align: left;
        color: #fff;
        min-height: 22px;
        padding: 2px 5px;
        background: $color_light_brown;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(9, 1, 0.1, bold);
        @include SP {
          @include font(10, 0, 0);
          min-height: 22px;
          padding: 5px 5px;
        }
      }
      .img {
        max-width: 178px;
        width: 100%;
        @include PC {
          @include aspect-ratio(178, 110);
        }
        @include SP {
          max-width: 100%;
          @include aspect-ratio(157, 97);
        }
      }
      .swiper-pagination {
        position: absolute;
        bottom: 10px;
        right: 0;
        text-align: right;
        line-height: 0;
        padding: 0 5px;
        &.none {
          display: none;
        }
      }
      .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        background: transparent;
        border: 1px solid #fff;
        opacity: 1;
        margin: 0 2px !important;
        &.swiper-pagination-bullet-active {
          background: #fff;
          opacity: 1;
        }
      }
    }
    &__link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
    }
    &__ttl {
      @include font(14, 22, 0.03, 500);
      @include PC {
        margin: 10px 0 0;
      }
      @include SP {
        margin: 4px 0 0;
        line-height: 26px;
        @include multiLineEllipsis(2);
      }
    }
    &__item {
      width: 178px;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &:last-child {
        margin: 0;
      }
      @include PC {
        margin-right: 15px;
        margin-bottom: 10px;
      }
      @include HOVER {
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      @include SP {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 4%;
        overflow: hidden;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
    &__wrap {
      @include SP {
        padding: 0 5px;
        margin: 32px auto 0;
      }
    }
  }
  .menu-about {
    @include flexbox;
    width: 100%;
    max-width: 1076px;
    margin: 46px auto 0;
    justify-content: space-between;
    @include SP {
      margin: 14px auto 0;
      flex-direction: column;
    }
    .btn {
      @include flexbox;
      @include SP {
        flex-direction: column;
      }
      a {
        border: 1px solid #000000;
        @include font(13, 1.5, 100, 500);
        padding: 15px 48px 12px 40px;
        margin-right: 6px;
        position: relative;
        @include SP {
          margin: 0 0 5px 0;
          @include font(14, 1.5, 0, 500);
          padding: 10px 0 10px 12px;
        }
        @include screen(768px, 923px) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 28px 12px 20px;
        }
        &::after {
          content: "";
          width: 13px;
          height: 14px;
          background: url(../img/common/icon/ico-up-right.svg) no-repeat center;
          background-size: contain;
          display: block;
          position: absolute;
          top: 47%;
          right: 30px;
          transform: translateY(-50%);
          @include SP {
            right: 20px;
          }
          @include screen(768px, 923px) {
            right: 10px;
          }
        }
        &.self {
          @include PC {
            padding: 15px 28px 12px;
          }
          &:after {
            content: unset;
          }
        }
        .break {
          display: none;
          @include screen(768px, 923px) {
            display: block;
          }
        }
      }
      .disable {
        border: 1px solid #d7d7d7;
        @include font(13, 1.5, 100, 500);
        color: #d7d7d7;
        padding: 14px 0 12px 4px;
        margin-right: 6px;
        @include SP {
          margin: 0 0 5px 0;
          padding: 10px 0 10px 12px;
          @include font(14, 1.5, 0, 500);
        }
      }
    }
    .policy {
      margin-top: 10px;
      @include SP {
        text-align: center;
        margin-top: 16px;
      }
      span {
        margin-top: 15px;
        @include font(13, 1.5, 100, 500);
        color: #767676;
        border-bottom: 1px solid #767676;
        padding-bottom: 6px;
      }
    }
    @include SP {
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
  @include SP {
    .fSocial {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include PC {
        margin: 46px 0 0 29px;
      }
      @include screen(768px, 884px) {
        margin: 20px 0 0 0px;
      }
      @include screen(885px, 1200px) {
        margin: 20px 0 0 12px;
      }
      @include SP {
        justify-content: center;
        margin: 40px 0 26px;
      }
      &-item {
        &:not(:last-child) {
          @include PC {
            margin-right: 26px;
          }
          @include screen(768px, 884px) {
            margin-right: 20px;
          }
        }
        @include SP {
          margin: 0 16px;
          &.instagram {
            img {
              width: 23px;
            }
          }
          &.youtube {
            img {
              width: 28px;
            }
          }
          &.facebook {
            img {
              width: 24px;
            }
          }
          &.tiktok {
            img {
              width: 22px;
            }
          }
          &.email {
            img {
              position: relative;
              //transform: scale(1.1);
              top: 2px;
            }
          }
        }
      }
    }
  }
}
body {
  &.layerOn {
    .inHeader {
      @include PC {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .hamberger {
      span {
        background: #000;
        &:nth-child(1) {
          transform: rotate(45deg) translateY(3px) translateX(3px);
        }
        &:nth-child(2) {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }
    }
    .logo {
      position: relative;
      @include PC {
        top: 11px;
      }
      @include SP {
        top: 5px;
        left: 20px;
      }
      img {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          display: block;
          @include SP {
            width: 180px;
          }
        }
      }
    }
    .gSocial {
      opacity: 0;
      pointer-events: none;
    }
    .layerMenu {
      .gNavi {
        opacity: 0;
        pointer-events: none;
      }
    }
    .menuNavi {
      opacity: 1;
      transform: translateX(0);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: vw-convert(919px, 1360);
        height: vw-convert(477px, 1360);
        background: url(../img/common/bg_hidden_menu.png) center/100% no-repeat;
        z-index: -1;
        @include SP {
          background: url(../img/common/bg_hidden_menu_sp.png) center/100% no-repeat;
          width: vw-convert(325px, 375);
          height: vw-convert(470px, 375);
        }
      }
    }
  }
}
.is-safari {
  .layerMenu {
    .gNavi {
      a {
        i {
          transform: translateY(0px);
        }
      }
    }
  }
}