@charset 'UTF-8';
.footer {
  max-width: 100%;
  background: #f8f8f7;
  margin: 73px auto 0;
  position: relative;
  @include SP {
    margin-top: 76px;
    overflow: hidden;
  }
  // .footer-contact {
  //   position: relative;
  //   color: #fff;
  //   @include PC {
  //     top: -148px;
  //     max-width: calc(100% - 188px);
  //     height: 302px;
  //     margin: 0 auto -148px;
  //     background: url(../img/common/other/img-contact.jpg);
  //     background-size: cover;
  //     background-position: center;
  //   }
  //   @include screen(768px, 1200px) {
  //     max-width: calc(100% - 40px);
  //   }
  //   @include SP {
  //     background: url(../img/common/other/img-contact-sp.jpg);
  //     padding: 47px 23px;
  //     min-height: 530px;
  //   }
  //   background-size: contain;
  //   &__inner {
  //     @include PC {
  //       max-width: 1173px;
  //       height: 100%;
  //       margin: 0 auto;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-start;
  //     }
  //   }
  //   &__ttl {
  //     @include PC {
  //       max-width: 300px;
  //       width: 100%;
  //       padding: 0 0 13px 49px;
  //     }
  //     @include screen(768px, 1200px) {
  //       max-width: 218px;
  //       padding-left: 15px;
  //     }
  //     .en {
  //       @include eb();
  //       @include font(35, 40, 0.05, 500);
  //       margin-bottom: 6px;
  //     }
  //     .jp {
  //       @include font(15, 40, 0.15, 500);
  //     }
  //     @include SP {
  //       text-align: center;
  //     }
  //   }
  //   &__link {
  //     @include font(16, 35, 0.15, 500);
  //     color: #fff;
  //     background-position: top 5px left 0;
  //     margin: 0 0 32px;
  //     @include PC {
  //       padding-left: 36px;
  //       display: flex;
  //       align-items: center;
  //       background-image: url("data:image/svg+xml,%3Csvg id='Group_80123' data-name='Group 80123' xmlns='http://www.w3.org/2000/svg' width='22.624' height='22.624' viewBox='0 0 22.624 22.624'%3E%3Cpath id='Path_364527' data-name='Path 364527' d='M17.762,14.475l4.161,2.312a1.367,1.367,0,0,1,.624,1.676,6.194,6.194,0,0,1-7.975,3.78A23.307,23.307,0,0,1,.391,8.062,6.194,6.194,0,0,1,4.171.087,1.369,1.369,0,0,1,5.848.711L8.159,4.871A1.7,1.7,0,0,1,7.941,6.85L6,9.035a14.253,14.253,0,0,0,7.6,7.6l2.185-1.942a1.7,1.7,0,0,1,1.978-.218Z' transform='translate(-0.009 -0.001)' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  //       background-size: 22px;
  //       background-repeat: no-repeat;
  //     }
  //     @include screen(768px, 1200px) {
  //       font-size: 12px;
  //       letter-spacing: 0;
  //       padding-left: 34px;
  //     }
  //     @include SP {
  //       text-align: center;
  //       border: 1px solid #fff;
  //       width: 100%;
  //       margin: 19px auto;
  //       padding: 12px 0px 8px;
  //       font-weight: 500;
  //       font-size: 12px;
  //       letter-spacing: 0.15em;
  //       text-align: center;
  //       color: #fff;
  //     }
  //     a {
  //       @include font(40);
  //       @include eb();
  //       position: relative;
  //       top: -4px;
  //       margin: 0 25px 0 51px;
  //       letter-spacing: 0.08em;
  //       color: #fff;
  //       @include screen(768px, 1200px) {
  //         margin: 0 16px;
  //         font-size: 30px;
  //         letter-spacing: 0;
  //         top: 0;
  //       }
  //       @include SP {
  //         display: block;
  //         font-size: 36px;
  //         letter-spacing: 0.1em;
  //         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='254.102' height='0.5' viewBox='0 0 254.102 0.5'%3E%3Cpath id='Path_364783' data-name='Path 364783' d='M-6771-9933.109h254.1' transform='translate(6771 9933.359)' fill='none' stroke='%23fff' stroke-width='0.5' stroke-dasharray='2'/%3E%3C/svg%3E%0A");
  //         background-repeat: repeat-x;
  //         margin: 5px auto 0;
  //         display: table;
  //         padding: 17px 0 0 20px;
  //         position: relative;
  //         &::before {
  //           content: "";
  //           position: absolute;
  //           top: 21px;
  //           left: -15px;
  //           width: 20px;
  //           height: 20px;
  //           background-image: url("data:image/svg+xml,%3Csvg id='Group_80123' data-name='Group 80123' xmlns='http://www.w3.org/2000/svg' width='20.725' height='20.725' viewBox='0 0 20.725 20.725'%3E%3Cpath id='Path_364527' data-name='Path 364527' d='M16.272,13.259l3.812,2.118a1.252,1.252,0,0,1,.571,1.535,5.674,5.674,0,0,1-7.306,3.463A21.35,21.35,0,0,1,.359,7.385,5.674,5.674,0,0,1,3.822.08,1.254,1.254,0,0,1,5.358.651L7.475,4.463a1.553,1.553,0,0,1-.2,1.812l-1.779,2a13.057,13.057,0,0,0,6.962,6.962l2-1.779a1.553,1.553,0,0,1,1.812-.2Z' transform='translate(-0.009 -0.001)' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  //         }
  //       }
  //     }
  //     small {
  //       color: #fff;
  //       position: relative;
  //       top: 4px;
  //       @include font(15, 35, 0.15, 500);
  //       @include screen(768px, 1200px) {
  //         font-size: 12px;
  //         letter-spacing: 0;
  //       }
  //       @include SP {
  //         display: block;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   &__info {
  //     width: 100%;
  //     margin: 0 0;
  //     position: relative;
  //     top: 6px;
  //     @include PC {
  //       border-left: 1px solid #959595;
  //       padding: 18px 49px 15px 77px;
  //       max-width: calc(100% - 300px);
  //     }
  //     @include screen(768px, 1200px) {
  //       padding: 10px 20px;
  //       max-width: calc(100% - 218px);
  //     }
  //   }
  //   &__list {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     @include SP {
  //       flex-wrap: wrap;
  //       justify-content: space-between;
  //     }
  //     li {
  //       width: 180px;
  //       height: 72px;
  //       background: transparent;
  //       border: 1px solid #fff;
  //       @include PC {
  //         margin-right: 9px;
  //         &:nth-child(2),
  //         &:nth-child(3) {
  //           a {
  //             padding-right: 12px;
  //             @include screen(768px, 1200px) {
  //               padding-right: 10px;
  //             }
  //           }
  //         }
  //         &:last-child {
  //           margin: 0;
  //           a {
  //             padding-right: 18px;
  //             @include screen(768px, 1200px) {
  //               padding-right: 10px;
  //             }
  //           }
  //         }
  //       }
  //       @include screen(768px, 1200px) {
  //         padding-right: 10px;
  //       }
  //       @include SP {
  //         width: 48.5%;
  //         margin: 4px 6px 7px 0;
  //         &:nth-child(2n + 2) {
  //           margin-right: 0;
  //         }
  //       }
  //       a {
  //         transition: all 0.3s ease-out;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         width: 100%;
  //         height: 100%;
  //         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%23fff'/%3E%3C/svg%3E%0A");
  //         background-size: 8px;
  //         background-position: center right 17px;
  //         padding-bottom: 3px;
  //         @include screen(768px, 1200px) {
  //           background-position: center right;
  //         }
  //         @include SP {
  //           background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%231b1c1f'/%3E%3C/svg%3E%0A");
  //           background-position: center right 6px;
  //           background-color: #fff;
  //         }
  //         background-repeat: no-repeat;
  //         span {
  //           color: #fff;
  //           position: relative;
  //           top: 2px;
  //           @include font(15, 20, 0.15, bold);
  //           @include screen(768px, 1200px) {
  //             @include font(12, "", 0);
  //           }
  //           @include SP {
  //             color: #1b1c1f;
  //           }
  //         }
  //       }
  //       @include HOVER {
  //         &:hover {
  //           a {
  //             opacity: 1;
  //             background: #fff;
  //             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%236c1c25'/%3E%3C/svg%3E%0A");
  //             background-size: 8px;
  //             background-position: center right 17px;
  //             background-repeat: no-repeat;
  //             span {
  //               color: #6c1c25;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  &-contact {
    background: #e3ded8 url(../img/common/other/bg_contact.png) no-repeat top right;
    background-size: contain;
    padding: 77px 0 82px;
    position: relative;
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 62px;
      @include SP {
        flex-direction: column;
        margin-bottom: 53px;
      }
    }
    &__ttl {
      width: 51%;
      .en {
        @include eb();
        @include font(40, 66, 50, 400);
        @include SP {
          font-size: 30px;
          margin-bottom: 5px;
        }
      }
      .jp {
        @include font(15, 24, 25, 500);
      }
      @include SP {
        width: 100%;
        text-align: center;
      }
    }
    &__txt {
      @include eb();
      width: 49%;
      padding-left: 19px;
      transform: translateY(27px);
      .tel {
        margin-top: 4px;
        @include SP {
          .link {
            position: relative;
            &::before {
              content: "";
              width: 21px;
              height: 21px;
              display: block;
              background: url(../img/common/icon/ico-phone.svg) no-repeat center;
              background-size: contain;
              position: absolute;
              top: 50%;
              left: 12px;
              transform: translateY(-50%);
            }
          }
        }
      }
      .txt-nor {
        @include font(18, 18, 100, 400);
        display: inline-block;
        min-width: 55px;
        margin-right: 8px;
        @include SP {
          font-size: 15px;
          margin-right: 0px;
          min-width: unset;
        }
      }
      .link {
        &-med {
          @include font(20, 20, 100, 400);
          @include SP {
            @include font(18, 20, 100, 400);
            &.tel {
              font-size: 22px;
            }
          }
        }
        &-sm {
          @include ffYG();
          @include font(12, 18, 150, 500);
          display: inline-block;
          transform: translate(10px, -2px);
          @include SP {
            display: block;
            transform: translate(2px, 1px);
          }
        }
        @include SP {
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 57px;
          margin: 4px 0 18px;
        }
      }
      @include SP {
        width: 95%;
        max-width: 313px;
        text-align: center;
        padding-left: 0;
      }
    }
    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      &-item {
        width: calc((100% - 159px) / 4);
        border-bottom: 1px solid #707070;
        padding-bottom: 9px;
        .en {
          @include eb();
          @include font(12, 19, 50, 400);
        }
        .jp {
          @include font(15, 30, 150, 700);
          @include SP {
            @include font(15, 20, 150, 700);
            margin-top: 5px;
            &.consultation {
              display: flex;
              align-items: flex-end;
            }
          }
          @include screen(768px, 1113px) {
            font-size: 14px;
          }
        }
        .break {
          display: none;
          @include SP {
            display: block;
          }
        }
        @include SP {
          width: calc((100% - 25px) / 2);
          padding-bottom: 14px;
          margin-bottom: 18px;
        }
        @include screen(955px, 1113px) {
          width: calc((100% - 60px) / 4);
          // padding-right: 30px;
        }
        @include screen(768px, 954px) {
          .break {
            display: block;
          }
          // .jp {
          //   min-height: 56px;
          //   transform: translateY(17px);
          // }
        }
      }
      @include SP {
        margin: 16px;
      }
    }
    @include SP {
      background: #e3ded8 url(../img/common/other/bg_contact_sp.png) no-repeat top right;
      background-size: contain;
      padding: 40px 0 25px;
    }
  }
  &-mix {
    padding: 79px 0 41px;
    .inner-l {
      @include min-screen(1201px) {
        padding-left: 72px;
      }
    }
    &__upper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include SP {
        flex-direction: column;
      }
    }
    &__lower {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 79px;
      .privacy {
        border-bottom: 1px solid #767676;
        margin: 2px 0 0 35px;
        padding-bottom: 2px;
        span {
          color: #767676;
          @include font(13, 22, 100, 500);
        }
        @include screen(768px, 1036px) {
          margin-left: 0px;
        }
        @include SP {
          order: 1;
          margin: 19px 0 0 0px;
        }
      }
      .site-lst {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        width: calc(100% - 200px);
        &-item {
          border: 1px solid #000;
          text-align: center;
          width: 100%;
          max-width: 224px;
          padding: 7px 8px 8px;
          position: relative;
          &:nth-child(1) {
            &:after {
              content: unset;
            }
            @include PC {
              max-width: fit-content;
              padding: 7px 30px 8px;
            }
          }
          &:nth-child(2) {
            @include PC {
              max-width: fit-content;
              padding: 7px 30px 8px;
            }
          }
          &::after {
            content: "";
            width: 13px;
            height: 14px;
            background: url(../img/common/icon/ico-up-right.svg) no-repeat center;
            background-size: contain;
            display: block;
            position: absolute;
            top: 47%;
            right: 12px;
            transform: translateY(-50%);
          }
          a {
            @include font(15, 30, 100, 500);
            @include screen(768px, 1200px) {
              font-size: 14px;
            }
            span {
              // @include screen(768px, 1063px) {
              //   display: block;
              // }
            }
            @include SP {
              @include font(14, 30, 0, 500);
            }
          }
          &.blur {
            opacity: 0.2;
            a {
              cursor: auto;
              opacity: 1;
            }
            &::after {
              display: none;
            }
            @include screen(768px, 1036px) {
              margin-right: 4px;
            }
            @include PC {
              margin-right: 9px;
            }
          }
          @include screen(768px, 1063px) {
            &:not(:first-child) {
              margin-left: 8px;
            }
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @include SP {
            max-width: 100%;
            text-align: left;
            height: 43px;
            margin-bottom: 5px;
            padding-top: 5px;
          }
          @include PC {
            &:not(:first-child) {
              margin-left: 12px;
            }
          }
        }
        @include SP {
          order: 0;
          width: 100%;
          flex-direction: column;
        }
      }
      @include screen(768px, 884px) {
        margin-top: 39px;
      }
      @include SP {
        flex-direction: column;
        align-items: center;
        margin-top: 31px;
      }
    }
    @include SP {
      padding: 56px 0 30px;
    }
  }
  &-social {
    margin-right: 20px;
    .logo {
      width: 199px;
    }
    .fSocial {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include PC {
        margin: 46px 0 0 0;
      }
      @include screen(768px, 884px) {
        margin: 20px 0 0 0px;
      }
      @include screen(885px, 1200px) {
        margin: 20px 0 0 0;
      }
      @include SP {
        justify-content: center;
        margin: 40px 0 26px;
      }
      &-item {
        &:not(:last-child) {
          @include PC {
            margin-right: 26px;
          }
          @include screen(768px, 980px) {
            margin-right: 20px;
          }
        }
        @include SP {
          margin: 0 16px;
          &.instagram {
            img {
              width: 23px;
            }
          }
          &.youtube {
            img {
              width: 28px;
            }
          }
          &.facebook {
            img {
              width: 24px;
            }
          }
          &.tiktok {
            img {
              width: 22px;
            }
          }

        }
        &.email {
          position: relative;
          top: 2px;
        }
      }
    }
    @include SP {
      text-align: center;
      margin-right: 0;
      width: 100%;
    }
  }
  &-content {
    width: 100%;
    max-width: 828px;
    display: grid;
    grid-template-columns: 26.8% 25.5% 24% auto;
    align-items: flex-start;
    @include screen(768px, 1200px) {
      max-width: 65%;
      grid-template-columns: 27% 27% 27% 19%;
    }
    @include SP {
      max-width: 100%;
      grid-template-columns: repeat(1, 100%);
    }
    &__item {
      li {
        margin-bottom: 43px;
        &.hasSub {
          margin-bottom: 11px;
          @include SP {
            grid-column: 1 / span 2;
            margin-bottom: 5px;
            a {
              padding: 20px 0 15px;
            }
          }
        }
        @include PC {
          &:last-child {
            margin: 0;
          }
          &.item01 {
            margin-bottom: 36px;
            @include screen(768px, 1200px) {
              margin-bottom: 34px;
            }
          }
        }
        @include screen(768px, 1200px) {
          margin-bottom: 19px;
        }
        a {
          display: block;
          span {
            display: block;
            @include font(14, 30, 0.1, "");
            color: #000;
            position: relative;
            @include screen(768px, 816px) {
              @include font(12, "", 0);
            }
            @include screen(817px, 1200px) {
              @include font(13, "", 0);
            }
            @include SP {
              padding: 0;
              @include font(15, 22, 0.1, "");
            }
            // &::before {
            //   content: "";
            //   position: absolute;
            //   width: 9px;
            //   height: 9px;
            //   top: 15px;
            //   left: 0;
            //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%236c1c25'/%3E%3C/svg%3E%0A");
            //   background-repeat: no-repeat;
            //   @include screen(768px, 1200px) {
            //     top: 10px;
            //   }
            //   @include SP {
            //     display: none;
            //   }
            // }
            &::after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0px;
              width: 18px;
              height: 1px;
              background: #a2977f;
              @include SP {
                display: none;
              }
            }
          }
          @include SP {
            // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%231b1c1f'/%3E%3C/svg%3E%0A");
            // background-repeat: no-repeat;
            // background-position: top 14px right 15px;
            padding: 19px 0 14px;
            border-bottom: 1px solid #adadad;
            // &.lastLine {
            //   padding: 10px 0 12px;
            // }
            // &.lastOne {
            //   // span {
            //   //   transform: translateY(23px);
            //   // }
            // }
          }
        }
        >ul {
          margin-top: 7px;
          @include PC {
            &.subLst01 {
              margin: 19px 0 0 11px;
            }
          }
          @include SP {
            display: none;
            margin-top: 20px;
            padding-left: 11px;
            border-bottom: 1px solid #adadad;
          }
          li {
            margin-bottom: 2px;
            padding-left: 16px;
            @include SP {
              margin-bottom: 9px;
              padding-left: 11px;
              &:last-child {
                margin-bottom: 13px;
              }
            }
            a {
              @include SP {
                padding: 0 !important;
                margin-bottom: 4px;
                border: none;
                background: none;
              }
              span {
                @include font(14, "", "", 500);
                @include SP {
                  padding-left: 16px;
                  @include font(14, 25, 100, 500);
                }
                &::before {
                  content: "";
                  background: #a2977f;
                  position: absolute;
                  top: 14px;
                  left: -11px;
                  width: 2px;
                  height: 2px;
                  border-radius: 50%;
                  @include screen(768px, 1200px) {
                    top: 13px;
                  }
                  @include SP {
                    left: 4px;
                    top: 11px;
                  }
                }
                &::before {
                  @include SP {
                    display: block;
                  }
                }
                &::after {
                  display: none;
                }
              }
            }
          }
        }
        @include SP {
          margin-bottom: 5px;
          display: grid;
          &.hasSub {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 17px;
              right: 8px;
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_82837' data-name='Group 82837' transform='translate(-320 -10879)'%3E%3Cg id='Ellipse_451' data-name='Ellipse 451' transform='translate(320 10879)' fill='none' stroke='%231b1c1f' stroke-width='1'%3E%3Ccircle cx='12' cy='12' r='12' stroke='none'/%3E%3Ccircle cx='12' cy='12' r='11.5' fill='none'/%3E%3C/g%3E%3Cg id='Group_80222' data-name='Group 80222' transform='translate(1.062 1.063)'%3E%3Cpath id='Path_364784' data-name='Path 364784' d='M-6486-9348.749v8.433' transform='translate(6816.938 20235)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3Cpath id='Path_364785' data-name='Path 364785' d='M0,0V8.432' transform='translate(326.722 10890.468) rotate(-90)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
              background-size: cover;
              z-index: 999;
            }
            &::before {
              content: "";
              width: 50%;
              height: 58px;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 10;
            }
            .plus-item {
              padding-right: 40%;
            }
            &.active {
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_82803' data-name='Group 82803' transform='translate(-320 -10879)'%3E%3Cg id='Ellipse_451' data-name='Ellipse 451' transform='translate(320 10879)' fill='none' stroke='%231b1c1f' stroke-width='1'%3E%3Ccircle cx='12' cy='12' r='12' stroke='none'/%3E%3Ccircle cx='12' cy='12' r='11.5' fill='none'/%3E%3C/g%3E%3Cg id='Group_80222' data-name='Group 80222' transform='translate(1.062 1.063)'%3E%3Cpath id='Path_364785' data-name='Path 364785' d='M0,0V8.432' transform='translate(326.722 10890.468) rotate(-90)' fill='none' stroke='%231b1c1f' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
              }
            }
          }
        }
      }
      @include PC {
        &:not(:first-of-type) {
          padding-top: 62px;
        }
      }
      @include SP {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer-copy {
    background: #fff;
    text-align: center;
    padding: 12px 20px 10px;
    @include SP {
      flex-wrap: wrap;
      padding: 23px 20px 71px 20px;
    }
    .copyright {
      color: #1b1c1f;
      @include font(12, 45, 105, 500);
      @include SP {
        @include font(13, 22, 100, 500);
      }
      @include SP {
        padding: 0;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    &__link {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @include SP {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding: 0 23px;
        position: relative;
        height: 50px;
        li {
          height: 100%;
          display: flex;
          align-items: center;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #adadad;
          width: calc(100% - 10px);
          height: 1px;
          margin: 0 auto;
        }
      }
      li {
        margin-right: 94px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        span {
          @include eb();
          color: #1b1c1f;
          @include font(14, 1, 0.05, 500);
        }
      }
    }
  }

  .footer-info {
    @include PC {
      max-width: 26%;
      width: 100%;
    }
    @include SP {
      text-align: center;
      padding: 43px 0 41px;
      .logo-ft {
        width: 200px;
        display: block;
        margin: 0 auto;
        img {
          width: 100%;
          position: relative;
          left: -15px;
        }
      }
    }
  }

  .footer-goup {
    position: fixed;
    bottom: 90px;
    right: 34px;
    background: transparent;
    width: 76px;
    height: 76px;
    cursor: pointer;
    transform: translateY(231px);
    transition: all 0.3s;
    opacity: 0;
    z-index: 999;
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
    &.fixed {
      position: absolute;
      bottom: -35px;
      right: 34px;
      transform: translateY(0);
      @include SP {
        right: 16px;
      }
    }
    @include SP {
      width: 64px;
      height: 64px;
      right: 16px;
      bottom: 65px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      border-radius: 50%;
      animation: move 2s infinite;
      transition: all 0.3s ease-out;
      transition-delay: 0.3s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 5px;
      width: 66px;
      height: 66px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #fff;
      animation: move1 2s infinite;
      transition: all 0.3s ease-out;
      @include SP {
        width: 56px;
        height: 56px;
        top: 4px;
        right: 4px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 4px auto;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
      text-align: center;
      color: #000;
      z-index: 1;
      position: relative;
      @include eb();
      @include SP {
        font-size: 14px;
      }
    }
    @include HOVER {
      &:hover {
        &::before {
          // animation: alternate 2s infinite;
          opacity: 0;
          animation: none;
        }
        &::after {
          transform: scale(0.5);
        }
      }
    }
  }
}
body.page-form {
  .footer-copy {
    padding-bottom: 18px;
  }
}
@keyframes move {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
    border-color: #b9b9b9;
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes move1 {
  0% {
    // transform: scale(0.5);
    border-color: #fff;
  }
  50% {
    // transform: scale(1);
    opacity: 0.8;
    border-color: #b9b9b980;
  }
  70% {
    // transform: scale(1.1);
    opacity: 1;
    border-color: #fff;
  }
  100% {
    opacity: 0;
    // transform: scale(1.4);
    border-color: #b9b9b980;
  }
}
@keyframes alternate {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  90% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}