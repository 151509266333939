@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 0 20px;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px auto;
  @include PC {
    padding-left: 30px;
  }
}

.alignleft {
  float: left;
  margin: 5px auto 20px 0;
  @include PC {
    padding-right: 30px;
  }
}

a img.alignright {
  float: right;
  margin: 5px 0 20px auto;
}

a img.alignnone {
  margin: 5px 0 20px;
}

a img.alignleft {
  float: left;
  margin: 5px auto 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 0 20px;
}

.wp-caption.alignleft {
  margin: 5px auto 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px auto;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.wp-video {
  max-width: 100%;
  margin-bottom: 40px;
  video {
    max-width: 100%;
    height: auto;
  }
}

.cms-content {
  font-size: 15px;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
  @include clearfix;
  ul,
  ol {
    margin-bottom: 55px;
    @include SP {
      margin-bottom: 40px;
    }
    li {
      letter-spacing: 0.05em;
      position: relative;
      margin-bottom: 3px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      &:before {
        content: "";
        position: relative;
        width: 8px;
        height: 8px;
        border: 2px solid $color_light_brown;
        border-radius: 50%;
        left: 0;
        // top: 8px;
        top: -2px;
        display: inline-block;
        margin: 0 5px 0 0;
      }
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      counter-increment: step-counter;
      &::before {
        content: counter(step-counter) ".";
        position: relative;
        font-size: 18px;
        letter-spacing: 0.05em;
        font-family: $eb;
        color: $color_light_brown;
        left: 0;
        top: 0;
        display: inline-block;
      }
    }
  }
  h2 {
    display: inline-block;
    font-size: 22px;
    line-height: 36px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #000;
    @include SP {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05em;
    font-weight: bold;
    background: #e3e3e3;
    padding: 13px 30px 13px;
    margin-bottom: 28px;
    @include SP {
      line-height: 30px;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 20px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05em;
    padding-left: 20px;
    margin-bottom: 28px;
    position: relative;
    @include SP {
      line-height: 30px;
      margin-bottom: 20px;
    }
    &:before {
      display: block;
      content: "";
      width: 6px;
      height: 6px;
      background: $color_light_brown;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 15px;
    }
  }

  .bnr {
    text-align: center;
  }
  p {
    line-height: 34px;
    margin-bottom: 35px;
  }
  a {
    // color: #ac1526;
    color: #a2977f;
    text-decoration: underline;
  }
  iframe {
    max-width: 100% !important;
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
  }
  table {
    table-layout: fixed;
    border: 1px solid $color_body;
    margin-bottom: 40px;
    td,
    th {
      border: 1px solid $color_body;
    }
  }
  [style="text-decoration: underline;"] {
    background: #f2f2ef;
    background: linear-gradient(0deg, #f2f2ef 5px, #fff 12px);
    text-decoration: none !important;
    padding-bottom: 5px;
  }
  [style="text-decoration: underline"] {
    background: #f2f2ef;
    background: linear-gradient(0deg, #f2f2ef 5px, #fff 12px);
    text-decoration: none !important;
    padding-bottom: 5px;
  }
}

h1 {
  font-size: 24px;
  line-height: 42px;
  letter-spacing: 0.1em;
  font-weight: bold;
  @include SP {
    font-size: 20px;
    line-height: 40px;
  }
}
